import { getFormData } from './commons';
import { buildOnetimePaymentButton } from './stripe';
import { addCouponEvents } from './coupon';
import { addHeartModalEvents } from './tip';

/* Apple Pay などの支払いボタンを作成 */
export const createOnetimePaymentButton = async (onetimeButtonElement, label, amount) => {
  if (!(typeof label === 'string' && typeof amount === 'number')) {
    throw new Error('Neither label nor amount is included within the form tag.');
  }

  const { paymentRequest, paymentRequestButton } = buildOnetimePaymentButton(label, amount);
  const result = await paymentRequest.canMakePayment(onetimeButtonElement);

  /* 利用できる支払い方法があるかどうかの結果 */
  /* @return { applePay: boolean, googlePay: boolean, link: boolean } */
  if (result) {
    paymentRequestButton.mount(onetimeButtonElement);
  } else {
    onetimeButtonElement.classList.add('hidden');
  }

  return paymentRequest
}

/* Apple Pay などの支払いボタンのラベル, 価格を更新 */
export const updateOnetimePaymentButton = (paymentRequest, label, amount) => {
  paymentRequest.update({
    total: { label, amount }
  })
}

const confirmOnetimePayment = async (form, ev) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const requestData = { ...getFormData(form), stripe_payment_method_id: ev.paymentMethod.id };

  const response = await fetch(form.action, {
    method: form.method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(requestData),
  });

  if (!response.ok) {
    ev.complete('fail');
  } else {
    ev.complete('success');
  }

  return await response.json();
}

/* Apple Pay などのボタンを埋め込む
   クーポンイベントの埋め込みも含む(Apple Pay 支払い時の金額の更新が必要なので分離不可) */
export const embedOnetimePaymentRequestButton = async (form) => {
  const onetimeButtonElement = form.querySelector('.js-onetime-payment-button')

  if (!onetimeButtonElement) {
    throw new Error('.js-onetime-payment-button is not included within the form tag');
  }

  const { label, amount } = getFormData(form)
  const paymentRequest = await createOnetimePaymentButton(onetimeButtonElement, label, amount)

  addCouponEvents(onetimeButtonElement, paymentRequest, form)
  addHeartModalEvents(paymentRequest, form)

  /* 支払いボタンを押し, 確定ボタンを押した後発生するイベント */
  paymentRequest.on('paymentmethod', async (ev) => {
    const data = await confirmOnetimePayment(form, ev)

    if (data['redirect_path']) {
      location.href = data['redirect_path'];
    } else {
      location.reload();
    }
  });
};
