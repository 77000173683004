document.addEventListener("turbo:load", () => {
  /* 同意チェック状況をフォームの action に反映 */
  const toggleAgreedParameter = (fieldElement, checked) => {
    const agreedKey = "agreed";
    const forms = fieldElement.querySelectorAll("form.button_to");

    forms.forEach((form) => {
      const url = new URL(form.action);
      if (checked) {
        url.searchParams.append(agreedKey, true);
      } else {
        url.searchParams.delete(agreedKey);
      }
      form.action = url;
    });
  };

  /* ボタン有効状態を反映 */
  const toggleSignupButtons = (fieldElement, checked) => {
    const signupButtons = fieldElement.querySelectorAll(".js-signup-button");
    signupButtons.forEach((element) => {
      if (checked) {
        element.removeAttribute("disabled");
        element.classList.remove("opacity-signup_button");
      } else {
        element.setAttribute("disabled", true);
        element.classList.add("opacity-signup_button");
      }
    });
  };

  /* 初回表示, 同意チェック変更時に上記を起動 */
  document.querySelectorAll(".js-signup-check").forEach((element) => {
    const fieldElement = element.closest(".js-signup-field");
    toggleSignupButtons(fieldElement, element.checked);
    toggleAgreedParameter(fieldElement, element.checked);

    element.addEventListener("click", () => {
      toggleSignupButtons(fieldElement, element.checked);
      toggleAgreedParameter(fieldElement, element.checked);
    });
  });
});
