document.addEventListener("turbo:load", () => {
  const urlParams = new URLSearchParams(location.search);
  const hasShowParameter = urlParams.get("show_modal") === "true";
  const PC_STYLE_MIN_WIDTH = 576;
  const ROOT_PATH = "/";

  const dispatchModalEvent = (element, eventName) => {
    const clickEvent = new Event(eventName);
    element.dispatchEvent(clickEvent);
  };

  //  Topページ遷移時に画面遷移時にログインモーダルを表示
  if (hasShowParameter && ROOT_PATH === location.pathname) {
    const buttonId = "js-login-button";
    const buttonElement = document.getElementById(buttonId);
    dispatchModalEvent(buttonElement, "click");
  }
});
