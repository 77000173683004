/* 画像ファイル名の表示(1枚の前提) */
document.addEventListener('turbo:load', () => {
  const DEFAULT_LABEL_NAME = '画像を選択'

  document.querySelectorAll('.js-filename-input').forEach((element) => {
    const input = element.querySelector('input[type=file]');

    const toggleFilename = () => {
      const label = element.querySelector('label');
      const file = input.files[0];
      if (file) {
        label.innerText = file.name;
      } else {
        label.innerText = DEFAULT_LABEL_NAME;
      }
    }

    input.addEventListener('change', () => {
      toggleFilename()
    });
  });
});
