/*
  タブ, タブパネルの親要素に .js-tab-component を付与
  タブに role = "tab" を付与
  タブパネルに role="tabpanel" を付与, 初期表示しないパネルには hidden を付与
*/

class TabSwitcher {
  constructor(component) {
    this.component = component;
    this.tabs = component.querySelectorAll('[role="tab"]');
    this.panels = component.querySelectorAll('[role="tabpanel"]');

    this.#errorHandling();
    this.tabs.forEach((tab, index) => {
      this.#initializeState(tab, this.panels[index], index);
      tab.addEventListener('click', () => this.#changeState(tab, index));
    });
  }

  #errorHandling() {
    const hiddenPanels = this.component.querySelectorAll('[role="tabpanel"][hidden]');

    if (this.tabs.length !== this.panels.length) {
      throw new Error('The number of tabs and panels is different.');
    } else if (this.panels.length - hiddenPanels.length > 1) {
      throw new Error('There are multiple panels that are not set to hidden.');
    }
  }

  #initializeState(tab, panel, index) {
    tab.setAttribute('aria-selected', String(!panel.hasAttribute('hidden')));
    tab.setAttribute('aria-id', `tab-${index + 1}`);
    panel.setAttribute('aria-labelledby', `tab-${index + 1}`);
  }

  #changeState(targetTab, targetIndex) {
    if (targetTab.getAttribute('aria-selected') === 'true') return;

    this.tabs.forEach((tab, index) => {
      const isClicked = index === targetIndex;
      tab.setAttribute('aria-selected', String(isClicked));
    });

    this.panels.forEach((panel, index) => {
      const isHidden = index !== targetIndex;
      isHidden ? panel.setAttribute('hidden', '') : panel.removeAttribute('hidden');
    });
  }
}

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-tab-component').forEach((component) => {
    new TabSwitcher(component);
  });
});
