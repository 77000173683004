// 使い方: アコーディオンのトグルボタンにjsAccordionIconクラスとdata-accordion-id属性を付与する
// アコーディオンの中身にjsAccordionContentクラスと対応する同じdata-accordion-id属性を付与する
document.addEventListener("turbo:load", () => {
  // アコーディオンアイコンにイベントリスナーを追加
  document.querySelectorAll('.jsAccordionIcon').forEach(function(icon) {
    icon.addEventListener('click', function() {
      const accordionId = this.getAttribute('data-accordion-id');
      toggleAccordion(accordionId, this);
    });
  });

  // デフォルトで開かれるアコーディオンを開く
  document.querySelectorAll('.jsAccordionContent[data-default="open"]').forEach(function(content) {
    const accordionId = content.getAttribute('data-accordion-id');
    const icon = document.querySelector(`.jsAccordionIcon[data-accordion-id="${accordionId}"]`);
    openAccordion(content, icon);
  });
});

function toggleAccordion(accordionId, icon) {
  const content = document.querySelector(`.jsAccordionContent[data-accordion-id="${accordionId}"]`);

  if (content) {
    if (content.classList.contains("active")) {
      closeAccordion(content, icon);
    } else {
      openAccordion(content, icon);
    }
  }
}

function openAccordion(content, icon) {
  // アコーディオンが開かれる前に一時的にmax-heightを'none'に設定して、実際の高さを計算
  content.style.maxHeight = "none";
  const actualHeight = content.scrollHeight + "120px";
  content.style.maxHeight = "0"; // max-heightを再度0に設定し、トランジションを有効に

  // トランジションを開始するために、短いタイムアウトを設定
  setTimeout(() => {
    content.style.maxHeight = actualHeight;
  }, 0);

  if (icon) {
    icon.classList.add("rotate");
  }
  content.classList.add("active");
}

function closeAccordion(content, icon) {
  content.style.maxHeight = null;

  if (icon) {
    icon.classList.remove("rotate");
  }
  content.classList.remove("active");
}
