const TurboFlashHelper = class {
  constructor() {
    document.addEventListener('turbo:before-fetch-response', (event) => {
      // 先にflashメッセージを出す
      var json = JSON.parse(event.detail.fetchResponse.header('X-Flash-Messages'));

      // メッセージを表示する
      for (let key in json) {
        window.flash_submit(key, decodeURIComponent(json[key]));
      }
      // turbo frameから リダイレクトを望まれていたら大本のページ単位でリダイレクトする
      if (event.detail.fetchResponse.header('X-Turbo-Connect') && typeof event.detail.fetchResponse !== 'undefined') {
        var response = event.detail.fetchResponse.response;
        if (response.redirected) {
          event.preventDefault();
          Turbo.visit(response.url, { action: 'replace' });
        }
      }
    });
  }
};
export default new TurboFlashHelper();
