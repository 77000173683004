import { fetchAccountToken, fetchBankToken, createAccount } from '../modules/stripe';
import AgeRestriction from '../modules/age_restriction';

document.addEventListener('turbo:load', () => {
  const personalForm = document.getElementById('personal-form');

  if (personalForm) {
    const redirectUrl = document.querySelector('.text-sm[href^="/account/"]').pathname;

    const isEdit = document.querySelector('input[name="_method"][value="patch"]');
    let method;
    if (isEdit) {
      method = 'PATCH';
    } else {
      new AgeRestriction(18, 120, 'year', 'month', 'day');
      method = 'POST';
    }

    const accountButton = document.getElementById('account-button');

    const registerAccount = async (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!personalForm.checkValidity()) {
        personalForm.classList.add('was-validated');
        return;
      }

      accountButton.disabled = true;
      accountButton.textContent = '送信中……';

      try {
        const [accountToken, bankToken] = await Promise.all([fetchAccountToken(), fetchBankToken()]);
        await createAccount(accountToken, bankToken, method);
        location.href = redirectUrl;
      } catch (e) {
        alert(e.message);
        accountButton.disabled = false;
        accountButton.textContent = '送信';
      }
    };

    personalForm.addEventListener('submit', registerAccount);
  }
});
