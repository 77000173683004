import { updateOnetimePaymentButton } from './onetime-payment';

/* ハートを贈るモーダル */
export const addHeartModalEvents = (paymentRequest, form) => {
  const modalElement = form.closest('.js-tip-modal')
  if (!modalElement) return

  const label = form.querySelector('[name=label]').value
  const hiddenPrice = form.querySelector('[name=amount]');
  const hiddenBody = form.querySelector('[name=body]');

  /* ハート選択モーダル */
  const selectModalContent = modalElement.querySelector('.select-modal-content');
  const tipBody = selectModalContent.querySelector('.tip-body');
  const tipSendButton = selectModalContent.querySelector('.js-tip-send-btn');
  const heartButtons = selectModalContent.querySelectorAll('.js-heart-price-btn');
  const priceButtons = selectModalContent.querySelectorAll('.js-heart-price');

  /* ハート確定モーダル */
  const confirmModalContent = modalElement.querySelector('.confirm-modal-content');
  const previewBody = confirmModalContent.querySelector('.preview-body');
  const tipPrice = confirmModalContent.querySelector('.tip-price');
  const chosenHeart = confirmModalContent.querySelector('.tip-chosen-heart');
  const backTipSelectButton = confirmModalContent.querySelector('.back-tip-select-btn');

  const tipSendButtonHandler = () => {
    /* メッセージが入力されておりハートが選択されている場合、ハートを贈るボタンを有効化 */
    if (tipBody.value.trim().length > 0 && tipPrice.innerHTML.trim().length > 0) {
      tipSendButton.removeAttribute('disabled');
    } else if (tipBody.value.trim().length == 0 || tipPrice.innerHTML.trim().length == 0) {
      tipSendButton.setAttribute('disabled', 'disabled');
    }
  }

  tipSendButtonHandler()

  /* ハート選択モーダル: 価格選択 */
  heartButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const price = button.value;
      let className;

      switch (price) {
        case '100':
          className = 'fal fa-heart';
          break;
        case '500':
          className = 'fas fa-heart';
          break;
        case '1000':
          className = 'fad fa-heart';
          break;
      }

      tipPrice.innerHTML = `¥${price}`;
      hiddenPrice.value = price;
      chosenHeart.innerHTML = `<i class="${className}"></i>`;
      updateOnetimePaymentButton(paymentRequest, label, Number(price))
      tipSendButtonHandler()

      /* 選択したハートと価格のみをピンク色に変更 */
      heartButtons.forEach((e) => e.classList.remove('choosing-heart'));
      priceButtons.forEach((e) => e.classList.remove('choosing-heart'));
      button.classList.add('choosing-heart');
      button.closest('.heart-btn__container').querySelector('.js-heart-price').classList.add('choosing-heart');
    });
  });

  /* ハート選択モーダル: ハートを贈るボタン */
  modalElement.querySelector('.js-tip-send-btn').addEventListener('click', () => {
    hiddenBody.value = previewBody.innerText = tipBody.value;

    selectModalContent.classList.add('hidden');
    confirmModalContent.classList.remove('hidden');
  });

  /* ハート確定モーダル: キャンセルボタン */
  backTipSelectButton.addEventListener('click', () => {
    selectModalContent.classList.remove('hidden');
    confirmModalContent.classList.add('hidden');
  });

  /* ハート選択モーダル: メッセージ */
  tipBody.addEventListener('input', tipSendButtonHandler);
}
