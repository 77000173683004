/* submit button に .js-loading-spinner を付与することで、送信開始時にローディングスピナーに置換, 終了後に戻す */
/* 文字は子要素の中に入れて使用すること */
/* turbo: false なフォームにも対応 */
const containerClass = 'js-spinner-container';

const handleSpinnerStart = (e) => {
  /* ローディングスピナーへの置換 */
  e.target.querySelectorAll('.js-loading-spinner').forEach((element) => {
    /* 既存の子要素を非表示 */
    [...element.children].forEach((childElement) => {
      childElement.classList.add('hidden');
    });

    /* ローディングアイコンを表示 or 追加 */
    let embeddedSpinner = element.querySelector(`.${containerClass}`);
    if (embeddedSpinner) {
      embeddedSpinner.classList.remove('hidden');
    } else {
      const container = document.createElement('div');
      container.classList.add('flex', 'justify-center', containerClass);

      const spinner = document.createElement('div');
      spinner.classList.add('global-loading-spinner');
      container.appendChild(spinner);
      element.appendChild(container);

      element.disabled = true;
    }
  });
};

const handleSpinnerEnd = (e) => {
  e.target.querySelectorAll('.js-loading-spinner').forEach((element) => {
    /* 既存の子要素を表示 */
    [...element.children].forEach((childElement) => {
      childElement.classList.remove('hidden');
    });

    /* ローディングアイコンを非表示 */
    const embeddedSpinner = element.querySelector(`.${containerClass}`);
    if (embeddedSpinner) embeddedSpinner.classList.add('hidden');

    element.disabled = false;
  });
};

document.addEventListener('turbo:submit-start', handleSpinnerStart);
document.addEventListener('turbo:submit-end', handleSpinnerEnd);

// 通常のHTMLフォームのsubmitイベント用のハンドラを追加
document.addEventListener('submit', (e) => {
  // Turbo Driveが無効なフォームの場合のみ処理を実行
  if (e.target.dataset.turbo === "false") {
    handleSpinnerStart(e);

    // 非同期処理が終了したらスピナーを隠す処理を追加
    e.target.addEventListener('ajax:complete', handleSpinnerEnd, { once: true });
  }
});
