// 主に広告バナーで使用する、閉じるボタンを押すと非表示になり、有効期限を設定する処理
// 使い方: 閉じるボタンにcloseButtonクラス、hiddenにしたい要素にcloseButtonTargetクラスを付与する
// 非表示期間を設定する場合は、ボタンをcloseAndSetExpiresButtonクラスに変更し、data-days属性を付与する

document.addEventListener('turbo:load', function(){
  function hideElement(targetElement) {
    if (targetElement && !targetElement.classList.contains('hidden')) {
      targetElement.classList.add('hidden');
    }
  }

  // 基本的に1ページに1つの閉じるボタンと非表示にしたい要素を想定しているので、SelectorAllは使わない
  const targetElement = document.querySelector('.closeButtonTarget');

  // 単純なクローズボタンの処理
  const closeButton = document.querySelector('.closeButton');
  if (closeButton) {
    closeButton.addEventListener('click', function(event){
      event.preventDefault();
      hideElement(targetElement);
    });
  }

  // クローズボタンと有効期限設定の処理
  const closeAndSetExpiresButton = document.querySelector('.closeAndSetExpiresButton');
  if (closeAndSetExpiresButton) {
    closeAndSetExpiresButton.addEventListener('click', function(event){
      event.preventDefault();
      hideElement(targetElement);

      // クッキー設定
      var date = new Date();
      var days = parseInt(this.getAttribute('data-days'), 10);
      date.setDate(date.getDate() + days);
      document.cookie = "hide_element=true; expires=" + date.toUTCString() + "; path=/";
    });
  }

  // クッキー確認
  const cookies = document.cookie.split("; ");
  const cookieHideElement = cookies.find(row => row.startsWith('hide_element='));

  // 当日中は非表示にする（日付が変わると再表示される）
  if (cookieHideElement) {
    hideElement(targetElement);
    var endOfDay = new Date();
    endOfDay.setHours(23,59,59,999);
    document.cookie = "hide_element=; expires=" + endOfDay.toUTCString() + "; path=/";
  }
});
