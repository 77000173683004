const stripePublishableKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
const stripe = new Stripe(stripePublishableKey);

// カード情報記載フォームを生成する関数
export const fetchCardElement = () => {
  const elements = stripe.elements();
  // カード情報記載フォームのスタイル（CSSにもフォームのスタイルを記載）
  const cardElementStyle = {
    base: {
      color: '#495057',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontWeight: 600,
      padding: '10px',
      '::placeholder': {
        color: '#848B91',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };
  return elements.create('card', { style: cardElementStyle, hidePostalCode: true });
};

// カード情報を送信し，支払いIDを返す
export const confirmCardSetup = async (card, clientSecret) => {
  const cardholderName = document.getElementById('cardholder-name');
  const paymentMethod = {
    payment_method: {
      card: card,
      billing_details: {
        name: cardholderName.value,
      },
    },
  };
  const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, paymentMethod);
  if (error) {
    throw new Error(error.message);
  } else if (setupIntent.status !== 'succeeded') {
    throw new Error(setupIntent.cancellation_reason);
  }
  return setupIntent.payment_method;
};

export const submitCard = async (stripe_payment_method_id) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  // リクエスト先を指定
  const response = await fetch('/cards', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ stripe_payment_method_id }),
  });

  if (!response.ok) {
    throw new Error('エラーが発生しました。ブラウザをリロードして再度お試し下さい。');
  }
};

// 医師アカウント登録用のトークン取得
const fetchImageToken = async (id) => {
  const form = new FormData();
  const file = document.querySelector(id).files[0];

  if (!file) {
    return undefined;
  }

  form.append('file', file);
  form.append('purpose', 'identity_document');
  const response = await fetch('https://uploads.stripe.com/v1/files', {
    method: 'POST',
    headers: { Authorization: `Bearer ${stripePublishableKey}` },
    body: form,
  });

  if (!response.ok) {
    throw new Error('添付された画像に問題があります');
  }

  return await response.json();
};

const fetchImageData = async () => {
  const frontData = await fetchImageToken('#id-front');
  const backData = await fetchImageToken('#id-back');

  let imageData = {};
  if (frontData && frontData.id) {
    imageData.front = frontData.id;
  } else {
    throw new Error('表画像を添付して下さい。');
  }
  if (backData && backData.id) {
    imageData.back = backData.id;
  }
  return imageData;
};

const fetchIndividualData = async () => {
  const phone = `+81${document.getElementById('phone').value.slice(1)}`;

  const address_kana = {
    line1: document.getElementById('line1').value,
    line2: document.getElementById('line2-kana').value,
  };

  const address_kanji = {
    postal_code: document.getElementById('postal-code').value,
    state: document.getElementById('state').value,
    city: document.getElementById('city').value,
    town: document.getElementById('town').value,
    line1: document.getElementById('line1').value,
    line2: document.getElementById('line2').value,
  };

  let individualData = {
    phone: phone,
    gender: document.getElementById('gender').value,
    address_kana: address_kana,
    address_kanji: address_kanji,
  };

  const edit = document.querySelector('input[name="_method"][value="patch"]');
  const unverified = document.getElementById('unverified');

  if (edit) {
    if (unverified) {
      const imageData = await fetchImageData();
      individualData.verification = { document: imageData };
    }
  } else {
    const imageData = await fetchImageData();
    individualData.verification = { document: imageData };
  }
  individualData.first_name_kana = document.getElementById('first-name-kana').value;
  individualData.last_name_kana = document.getElementById('last-name-kana').value;
  individualData.first_name_kanji = document.getElementById('first-name-kanji').value;
  individualData.last_name_kanji = document.getElementById('last-name-kanji').value;
  individualData.dob = {
    day: document.getElementById('day').value,
    month: document.getElementById('month').value,
    year: document.getElementById('year').value,
  };

  return individualData;
};

const fetchTokenParams = async () => {
  const individualData = await fetchIndividualData();
  return {
    business_type: 'individual',
    individual: individualData,
    tos_shown_and_accepted: true,
  };
};

export const fetchAccountToken = async () => {
  const tokenParams = await fetchTokenParams();
  const { token, error } = await stripe.createToken('account', tokenParams);
  if (error) {
    console.log(error.message);
    throw new Error('アカウント情報に問題があります。');
  }
  return token;
};

export const fetchBankToken = async () => {
  const bankNum = document.getElementById('bank-number').value;
  const branchNum = document.getElementById('branch-number').value;
  const routingNum = bankNum + branchNum;
  const bankAccount = {
    country: 'JP',
    currency: 'jpy',
    account_holder_name: document.getElementById('account-holder-name').value,
    account_holder_type: 'individual',
    routing_number: routingNum,
    account_number: document.getElementById('account-number').value,
  };

  const { token, error } = await stripe.createToken('bank_account', bankAccount);
  if (error) {
    console.log(error.message);
    throw new Error('銀行口座情報に問題があります。');
  }
  return token;
};

export const createAccount = async (accountToken, bankToken, method) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const params = {
    account_token_id: accountToken.id,
    bank_token_id: bankToken.id,
  };
  // リクエスト先を指定
  const response = await fetch('/accounts', {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(params),
  });
  if (!response.ok) {
    throw new Error('エラーが発生しました。ブラウザをリロードして再度お試し下さい。');
  }

  const data = await response.json();

  if (data.payouts_enabled) {
    alert('登録申請を行いました。通常、1時間以内に完了します。');
  } else {
    alert('口座登録申請を受け付けました。');
  }
};

/* Apple Pay, Google Pay, Link の支払い設定 */
export const buildOnetimePaymentButton = (label, amount) => {
  const paymentRequest = stripe.paymentRequest({
    country: 'JP',
    currency: 'jpy',
    total: { label, amount },
    requestPayerName: true,
    requestPayerEmail: true,
  });

  const style = {
    paymentRequestButton: {
      type: 'default',
      // One of 'default', 'book', 'buy', or 'donate'
      // Defaults to 'default'

      theme: 'dark',
      // One of 'dark', 'light', or 'light-outline'
      // Defaults to 'dark'

      height: '40px',
      // Defaults to '40px'. The width is always '100%'.
    }
  }

  const elements = stripe.elements();
  const paymentRequestButton = elements.create('paymentRequestButton', {
    paymentRequest, style
  });

  return { paymentRequest, paymentRequestButton }
}
