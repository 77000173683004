document.addEventListener('turbo:load', () => {
  flatpickr.localize(flatpickr.l10ns.ja);

  const defaultOptions = {
    disableMobile: true,
  };

  document.querySelectorAll('.js-flatpickr').forEach((element) => {
    const options = { ...defaultOptions };

    const minDate = element.dataset.flatpickrMinDate;
    if (minDate) options.minDate = minDate;

    flatpickr(element, options);
  });
});
