import { getFormData } from './commons';
import { updateOnetimePaymentButton } from './onetime-payment';

/* Stripe API で Promotion code 情報を取得 */
const fetchPromotionCodeInfos = async (form) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const formData = new FormData(form);
  let requestBody = Object.fromEntries(formData.entries());

  const response = await fetch(`/stripes/promotion_codes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error('エラーが発生しました。ブラウザをリロードして再度お試し下さい。');
  }

  const result = await response.json()

  /* 割引表示内容を生成 */
  if (result.data?.percent_off) {
    result['detail'] = `${result.data.percent_off}％引き`
  } else if (result.data?.amount_off) {
    result['detail'] = `${result.data.amount_off.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })}円引き`
  }

  return result;
}

/* クーポンコードのフィールド入力後の blur イベントで, クーポンの有効性, クーポン情報を表示
   Apple Pay などのボタンの更新も行う */
export const addCouponEvents = (onetimeButtonElement, paymentRequest, form) => {
  const { label, amount } = getFormData(form)
  let discounted_amount = amount

  const couponInputElement = form.querySelector('.js-coupon-input-element')
  const couponDiscount = form.querySelector('.js-coupon-discount')
  const couponError = form.querySelector('.js-coupon-errors');
  const submitButton = form.button;

  /* フォームの送信ボタンが存在するときは, 活性／非活性の反映 */
  const activationSubmitButton = () => {
    if (!submitButton) return

    submitButton.disabled = false;
    submitButton.classList.remove('btn-general__cancel');
    submitButton.classList.add('btn-general');
  }

  const deactivationSubmitButton = () => {
    if (!submitButton) return

    submitButton.classList.remove('btn-general');
    submitButton.classList.add('btn-general__cancel');
    submitButton.disabled = true;
  }

  /* 入力されたクーポンコードが利用可能かどうかを確認し, 利用できる場合は,
     適用後の価格, 割引額 に置き換える */
  couponInputElement?.addEventListener('change', async () => {
    onetimeButtonElement.classList.add('!hidden')
    deactivationSubmitButton()
    couponDiscount.textContent = couponError.textContent = '';
    discounted_amount = amount
    if (couponInputElement.value) {
      const result = await fetchPromotionCodeInfos(form)
      if (result.data) {
        couponDiscount.textContent = result.detail
        discounted_amount = result.data.discounted_amount
        form.querySelector('[name=discounted_amount]').value = result.data.discounted_amount
        updateOnetimePaymentButton(paymentRequest, label, discounted_amount)
      } else {
        couponError.textContent = result.detail;
      }
    }

    if (couponError.textContent.length) {
      couponInputElement.classList.add('error');
    } else {
      couponInputElement.classList.remove('error');
      activationSubmitButton();
      onetimeButtonElement.classList.remove('!hidden')
    }
  })
}
