/* 画像プレビュー */

/*
  .js-image-preview の子要素に
  プレビュー画像 img.js-image-preview-target と
  input.js-image-preview-file[type="file"] を配置

  削除ボタンが必要である場合は、.relativeの子要素に img.js-image-preview-target と .js-image-preview-delete を配置すること

  画像を外した際にデフォルト画像に戻す必要があれば, img.js-image-preview-target に data-default-preview-image="画像のパス" を追加
*/

const buildImagePreview = () => {
  document.querySelectorAll('.js-image-preview').forEach((element) => {
    const imageElement = element.querySelector('img.js-image-preview-target');
    const defaultImageSrc = imageElement.dataset.defaultPreviewImage || ''
    const inputElement = element.querySelector('input.js-image-preview-file[type="file"]');
    const deleteButtonElement = element.querySelector('.js-image-preview-delete')

    const deleteContainer = document.createElement('div')
    deleteContainer.classList.add('absolute', 'top-4', 'right-4')
    const deleteButton = document.createElement('i')
    deleteButton.classList.add('fas', 'fa-times-circle', 'fa-2x', 'cursor-pointer')
    deleteContainer.appendChild(deleteButton);

    inputElement.addEventListener('change', () => {
      const file = inputElement.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          imageElement.setAttribute('src', String(reader.result));
        };
        reader.readAsDataURL(file);
        deleteButtonElement?.appendChild(deleteContainer)
      } else {
        imageElement.setAttribute('src', defaultImageSrc);
        deleteButtonElement?.removeChild(deleteContainer)
      }
    });

    deleteContainer.addEventListener('click', (e) => {
      inputElement.value = '';
      imageElement.setAttribute('src', defaultImageSrc);
      deleteButtonElement?.removeChild(deleteContainer)
    })
  });
};

document.addEventListener('turbo:load', buildImagePreview);
document.addEventListener('call-image-preview', buildImagePreview);
