window.flash_submit = (type, json_text) => {
  const json = JSON.parse(json_text);
  let data = '';
  let type_class = '';
  json.forEach((ele) => {
    // #flashTextContent はコンバージョン計測にGTMで使用
    data += `<div id='flashTextContent'>${ele}</div>`;
  });
  if (type == 'alert') {
    type_class = 'border-site-general bg-site-main';
  } else {
    type_class = 'border-site-text bg-site-sub';
  }

  $(function () {
    if (window.flash_timer) {
      clearTimeout(window.flash_timer);
      $('#js-flash-messages').removeAttr('style');
    }

    $('#js-flash-messages').html(
      `<div class="fixed top-header-height left-0 right-0 z-[99999]"><div class='m-2 border-2 rounded px-12 py-4 flex items-center ${type_class}'><div class='flex-1'>${data}</div><div onclick='$(this).parent().fadeOut(200)' class='text-2xl pl-4 cursor-pointer'>×</div></div></div>`
    );
    window.flash_timer = setTimeout("$('#js-flash-messages').fadeOut('slow')", 4000);
    setTimeout(() => $('#js-flash-messages').remove(), 4000);
  });
};
