/* object の値が「整数の文字列」である場合に「整数」に置き換えた object を返す巻数 */
const convertObjectIntegers = (obj) => Object.fromEntries(
  Object.entries(obj).map(([key, value]) => [key, /^-?\d+$/.test(value) ? parseInt(value) : value])
);

/* form 内のパラメータを取得 */
export const getFormData = (form) => {
  const formData = new FormData(form);
  const data = Object.fromEntries(formData.entries());
  return convertObjectIntegers(data)
}
