// app/javascript/count_up.js

import { CountUp } from 'countup.js';

document.addEventListener('turbo:load', countUpAnimation);
document.addEventListener('turbo:before-stream-render', countUpAnimation);

function countUpAnimation(event) {
  const totalFundElem = document.getElementById("total-fund");
  if (!totalFundElem) return; // totalFundElemが存在しない場合、関数を抜ける

  let startValue; // turbo:loadの場合は0から、streamの場合は渡された値からカウントアップを開始
  if (event.type === 'turbo:load') {
    startValue = 0;
  } else if (event.type === 'turbo:before-stream-render' || event.type === 'turbo:render') {
    startValue = parseFloat(totalFundElem.dataset.countUpStartValue);
  }
  const endValue = parseFloat(totalFundElem.dataset.total);

  // CountUp.js を使用してカウントアップアニメーションを実行
  var options = {
    startVal: startValue,
    decimalPlaces: 0, // 小数点以下の桁数を0に設定
    duration: 2,  // アニメーションの時間を2秒に設定
    prefix: '¥', // 値の前に '¥' を追加
  };
  var countUp = new CountUp('total-fund', endValue, options);
  if (!countUp.error) {
    countUp.start();
  } else {
    console.error(countUp.error);
  }
}
