/* 三点リーダー */
export const buildEllipsisButton = () => {
  const adminToggleBtn = $('.js-adminmenu-btn');
  const adminToggleIcon = $('.js-adminmenu-icon');

  adminToggleBtn.map((index, toggleBtn) => {
    $(toggleBtn).off('click')
    $(toggleBtn).on('click', () => {
      $(toggleBtn).parent().find('.js-adminmenu-content').slideToggle();
      $(toggleBtn).children(adminToggleIcon).toggleClass('fa-ellipsis-h')
      $(toggleBtn).children(adminToggleIcon).toggleClass('fa-times')
    });
  });
};

document.addEventListener('turbo:load', () => {
  buildEllipsisButton();
});

document.addEventListener('call-ellipsis', () => {
  buildEllipsisButton();
});
