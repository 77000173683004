document.addEventListener('turbo:load', () => {
  const toolbarOptions = [
    [{ header: [2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'bullet' }, { list: 'ordered' }],
    [{ align: [] }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
  ];

  const options = {
    modules: {
      toolbar: toolbarOptions,
    },
    theme: 'bubble',
  };

  const uploadPath = '/quill_uploads';
  const uploadNameAttribute = 'quill_upload[image]';

  /* 画像アイコンを押したときの処理(追加ではなく, 上書き処理となる) */
  const uploadImageHandler = (quill) => {
    /* 画像選択画面の表示 */
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    /* 画像選択時の処理 */
    input.onchange = () => {
      const file = input.files[0];

      if (/^image\//.test(file.type)) {
        uploadImage(quill, file);
      } else {
        alert('画像ファイルを選択して下さい');
      }
    };
  };

  const uploadImage = async (quill, file) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const formData = new FormData();
    formData.append(uploadNameAttribute, file);

    const response = await fetch(uploadPath, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('画像のアップロードに失敗しました。');
    }

    const data = await response.json();
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', data.url);
    addUploadId(quill.container, data.upload_id);
  };

  const embedTextarea = (element) => {
    const textarea = document.createElement('textarea');
    textarea.className = 'hidden js-quill-textarea';
    textarea.setAttribute('name', element.dataset.name);
    element.appendChild(textarea);
  };

  const addUploadId = (element, upload_id) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'upload_ids[]');
    input.setAttribute('value', upload_id);
    element.appendChild(input);
  };

  /* id を埋め込む（broadcast の target で利用） */
  const embedEditorId = (element) => {
    const id = element.dataset.qlEditorId
    if (!id) return;

    element.querySelector('.ql-editor').setAttribute('id', id)
  };

  const quillNodes = document.querySelectorAll('.js-quill');
  const form = quillNodes[0]?.closest('form');

  quillNodes.forEach((element) => {
    const quill = new Quill(element, options);
    quill.getModule('toolbar').addHandler('image', () => uploadImageHandler(quill));
    embedTextarea(element);
    embedEditorId(element);
  });

  /* 送信前に入力値を textarea にコピー */
  form?.addEventListener('submit', () => {
    quillNodes.forEach((element) => {
      const body = element.querySelector('.ql-editor').innerHTML;
      const textarea = element.querySelector('.js-quill-textarea');
      textarea.value = body;
    });
  });
});
