document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-swiper').forEach((swiperElement) => {
    const slideCount = swiperElement.querySelectorAll('.swiper-slide').length;
    let config = {};

    switch (swiperElement.dataset.swiperType) {
      case 'article':
        config = {
          loop: slideCount > 1,
          spaceBetween: 20,
          centeredSlides: true,
          slidesPerView: 1.2,
          initialSlide: 0,
          breakpoints: {
            320: {
              slidesPerView: 1.2,
            },
            420: {
              slidesPerView: 1.4,
            },
            577: {
              slidesPerView: 1.5,
            },
            688: {
              slidesPerView: 1.8,
            },
            920: {
              slidesPerView: 2.0,
            },
            1000: {
              slidesPerView: 2.6,
            },
            1200: {
              slidesPerView: 2.8,
            },
            1400: {
              slidesPerView: 3.2,
            },
            1600: {
              slidesPerView: 3.8
            },
          },
        };
        break;
    }

    let swiper = new Swiper(swiperElement, config);

    /*
      スライドを遅延追加したい場合に利用
      .js-swiper の直下に .js-swiper-lazy-target を追加。ここに要素を追加するどスライドに追加される
      発動は1回限りとしている。必要があれば observer.disconnect() を調整すること
    */
    const observerTarget = swiperElement.querySelector('.js-swiper-lazy-target');

    if (observerTarget) {
      let observer;

      const observerCallback = () => {
        observer.disconnect();

        const sliders = observerTarget.querySelectorAll('.swiper-slide');
        sliders.forEach((slider) => {
          slider.remove();
          swiper.appendSlide(slider);
        });
      };

      const observerConfig = {
        childList: true,
        subtree: false,
      };

      observer = new MutationObserver(observerCallback);

      /* 監視前に要素が追加された場合の対処 */
      observerCallback();

      observer.observe(observerTarget, observerConfig);
    }
  });
});
