import { fetchCardElement, confirmCardSetup, submitCard } from '../modules/stripe';

document.addEventListener('turbo:load', () => {
  if (location.pathname.search(/^\/cards/) !== -1) {
    const cardElement = fetchCardElement();
    const cardErrors = document.getElementById('js-card-errors');
    const cardEditButton = document.getElementById('js-card-edit-button');

    // カード情報フォームを生成
    cardElement.mount('#js-card-element');
    // カード情報入力不備のエラー表示
    cardElement.on('change', (e) => {
      if (e.error) {
        cardErrors.textContent = e.error.message;
        cardEditButton.disabled = true;
      } else {
        cardErrors.textContent = '';
        cardEditButton.disabled = false;
      }
    });

    const form = document.getElementById('js-card-attach-form');
    const userId = form.dataset.user_id;
    const isEdit = form.dataset.is_edit;
    const redirectUrl = form.dataset.redirect_url || `/account/${userId}`;

    const changePaymentMethod = async (e) => {
      e.preventDefault();
      cardEditButton.disabled = true;
      cardEditButton.textContent = '送信中……';
      try {
        const paymentMethod = await confirmCardSetup(cardElement, form.dataset.secret);
        await submitCard(paymentMethod);
        alert(`カード情報の${isEdit}が完了しました!`);
        window.location.href = redirectUrl;
      } catch (e) {
        cardErrors.textContent = e.message;
        cardEditButton.disabled = false;
        cardEditButton.textContent = `カード情報を${isEdit}`;
      }
    };
    form.addEventListener('submit', changePaymentMethod);
  }
});
