
/* Apple Pay などの支払いボタンに関するJS */

/* NOTE: 支払いボタンを生成するモーダルの form に label, amount を設定しておくこと */
/* label, amount は支払い画面で表示される */

import { embedOnetimePaymentRequestButton } from '../modules/onetime-payment';

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('.js-payment-form').forEach((form) => {
    embedOnetimePaymentRequestButton(form);
  });
});
