document.addEventListener('turbo:load', () => {
  const urlParams = new URLSearchParams(location.search);
  const hasShowParameter = urlParams.get('show_modal') === 'true';
  const modalKey = 'show-modal-id';
  const signupButtons = document.querySelectorAll('.js-signup-button');
  const isLoggedIn = signupButtons.length === 0;

  /* ログアウト時にチップモーダルを開こうとした際, 新規登録ボタンに モーダルID を設定 */
  document.querySelectorAll('.js-set-modal-value').forEach((element) => {
    const modalId = element.dataset.modalId;

    element.addEventListener('click', () => {
      signupButtons.forEach((signupButton) => {
        signupButton.dataset.modalId = modalId;
      });
    });
  });

  /* ログイン時に, リダイレクト時に初期表示するモーダルID を LocalStorage を登録 */
  signupButtons.forEach((signupButton) => {
    signupButton.addEventListener('click', () => {
      const modalId = signupButton.dataset.modalId;
      localStorage.setItem(modalKey, modalId);
    });
  });

  /* リダイレクト後に LocalStorage に登録されていた モーダルID に対応するモーダルを表示 */
  document.querySelectorAll('.micromodal').forEach((modalElement) => {
    const modalId = modalElement.id;

    if (isLoggedIn && hasShowParameter && localStorage.getItem(modalKey) === modalId) {
      /* ログイン後のリダイレクト時に, 開こうとしていたチップモーダルを開く */
      MicroModal.show(modalId);
      localStorage.removeItem(modalKey);
    }
  });
});
