// コンポーネント 'ClipboardCopyUrl'で使用
document.addEventListener('turbo:load', () => {
  const copyButtons = document.querySelectorAll('.copyButton');

  copyButtons.forEach((copyButton) => {
    copyButton.addEventListener('click', async (event) => {
      event.preventDefault();

      const url = copyButton.getAttribute('data-url');

      try {
        await navigator.clipboard.writeText(url);

        alert('URLをコピーしました!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    });
  });
});
