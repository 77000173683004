// 使い方: viewポートに入った時にフェードインさせたい要素にfade-in-scrollクラスを付与する
// delay: フェードインを遅らせたい場合は、data-delay属性に秒数を指定する（例: <div class="fade-in-scroll" data-delay="1">）

document.addEventListener('turbo:load', (event) => {
  document.addEventListener('turbo:load', () => fadeInAnimation("fade-in-turbo-load"));
  document.addEventListener('turbo:before-stream-render', () => fadeInAnimation("fade-in-turbo-stream"));

  // Intersection Observer APIを利用して、ビューポートに入った時のフェードインを実装
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        fadeInAnimation("fade-in-scroll");
      }
    });
  });

  const fadeInScrollElems = document.querySelectorAll('.fade-in-scroll');
  fadeInScrollElems.forEach(elem => observer.observe(elem));
});

function fadeInAnimation(className) {
  const fadeInElems = document.querySelectorAll(`.${className}`);
  fadeInElems.forEach(fadeInElem => {
    // すでにフェードインが実行された要素をチェック
    if (fadeInElem.dataset.animated) {
      return;
    }

    fadeInElem.style.opacity = "0";
    fadeInElem.classList.remove('fade-in-active');
    fadeInElem.classList.remove('hidden');

    let delay = fadeInElem.dataset.delay ? fadeInElem.dataset.delay * 1000 : 10; // data-delay属性があればその値を、なければ10msをデフォルトとする

    setTimeout(() => { // タイマーを設定してフェードインアニメーションを開始
      fadeInElem.style.opacity = "1";
      fadeInElem.classList.add('fade-in-active');
    }, delay); // data-delay属性で指定した時間後にフェードインを開始（ブラウザがスタイル変更を認識できるようにするための遅延）

    // フェードインが実行されたフラグをセット
    fadeInElem.dataset.animated = "true";
  });
}
