document.addEventListener('turbo:submit-end', (e) => {
  if (e.detail.success) {
    const dataset = e.target.dataset;

    /* 初期表示で一番下にスクロール */
    const scrollDirection = dataset.scrollDirection;
    if (scrollDirection && scrollDirection === 'bottom') {
      scroll(0, document.documentElement.scrollHeight);
    }
  }
});
